import { useEffect, useState } from "react";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../../aws-exports";
Amplify.configure(awsconfig);

export const useSubmitProduct = ({ formData }) => {
  const [restart, updateRestart] = useState(null);
  const {
    product_url,
    business_type,
    transaction_version,
    bank_name,
  } = formData;

  console.log("Fomdata:", formData);

  async function getData() {
    console.log("transaction:", transaction_version);
    try {
      const RestartResponse = await API.post("dataloadsapi", "/updateproduct", {
        body: {
          URL: product_url,
          BankName: bank_name,
          BusinessType: business_type,
          TransactionVersion: transaction_version,
        },
      });
      updateRestart(RestartResponse.Items);
      console.log("Data Records:", RestartResponse.Items);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  useEffect(() => {
    getData();
    return () => {
      console.log("unmount");
    };
  }, []);

  return { restart };
};
