import React, { useState } from "react";
import { withRouter } from "react-router";
import { useForm, useStep } from "react-hooks-helper";
import "bootstrap/dist/css/bootstrap.min.css";
import DataInfo from "./DataInfo";
import Submit from "./Submit";
import { useFetch } from "./useFetch";
import Loading from "./Loading";

const steps = [
  { id: "plparams" },
  { id: "plinfo" },
  { id: "createrpm" },
  { id: "codedeployrun" },
  { id: "submit" },
];

const defaultData = {
  tlp_pl_name: "GI_Name_0001",
  regression_name: "Regression_001",
  instance_selected: "",
  transaction_startdate: "01-Jun-2020",
  transaction_enddate: "31-Jun-2020",
  gi_startdate: "01-Jun-2020",
  gi_enddate: "31-Jun-2020",
  pl_startdate: "01/01/2020",
  pl_enddate: "07/30/2020",
};

function ViewDataload() {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const [refresh, setRefresh] = useState(true);

  const datarecords = useFetch(setRefresh, refresh);

  const props = {
    datarecords,
    formData,
    setForm,
    navigation,
    setRefresh,
    refresh,
  };

  if (datarecords) {
    return <DataInfo {...props} />;
  } else {
    return <Loading />;
  }
}

export default withRouter(ViewDataload);
