import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import SetupTransactionV1 from "./pages/SetupTransactionV1.js";
import PriceList from "./pages/PriceList.js";
import ViewDataload from "./pages/ViewDataload.js";
import GlobalInput from "./pages/GlobalInput.js";
import Products from "./pages/products/Products.js";
import LoadAccount from "./pages/LoadAccount.js";
import LoadProduct from "./pages/LoadProduct.js";
import InProgress from "./pages/InProgress.js";
import Completed from "./pages/Completed.js";
import RestartDataload from "./pages/RestartDataload.js";
import ValidateStage from "./pages/ValidateStage.js";
import NavBar from "./pages/NavBar.js";
import PreAction from "./pages/PreAction.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);

  Auth.currentAuthenticatedUser({
    bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then((user) => {
      console.log("User Attributes:", user.attributes);
      // To initiate the process of verifying the attribute like 'phone_number' or 'email'

      //Auth.verifyCurrentUserAttributeSubmit("email", "the_verification_code")
      //  .then(() => {
      //    console.log("phone_number verified");
      //  })
      //  .catch((e) => {
      //    console.log("failed with error", e);
      //  });
    })
    .catch((err) => console.log("Error: ", err));

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          return null;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  async function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return (
    <AmplifyAuthenticator>
      <div>
        {user ? (
          <>
            <Router>
              <div>
                <NavBar />
                <Switch>
                  <Route exact path="/" component={Home}>
                    <Home user={user} />
                  </Route>
                  <Route path="/transaction_v1" component={SetupTransactionV1}>
                    <SetupTransactionV1 />
                  </Route>
                  <Route path="/transaction_v2" component={SetupTransactionV1}>
                    <SetupTransactionV1 />
                  </Route>
                  <Route path="/global-assumption" component={GlobalInput}>
                    <GlobalInput />
                  </Route>
                  <Route path="/loadaccount" component={LoadAccount}>
                    <LoadAccount />
                  </Route>
                  <Route path="/loadproduct" component={LoadProduct}>
                    <LoadProduct />
                  </Route>
                  <Route path="/pricelist" component={PriceList}>
                    <PriceList />
                  </Route>
                  <Route path="/viewdata" component={ViewDataload}>
                    <ViewDataload />
                  </Route>
                  <Route exact path="/preaction" component={PreAction}>
                    <PreAction />
                  </Route>
                  <Route exact path="/inprogress" component={InProgress}>
                    <InProgress />
                  </Route>
                  <Route
                    exact
                    path="/restart_dataload"
                    component={RestartDataload}
                  >
                    <RestartDataload />
                  </Route>
                  <Route exact path="/validate_stage" component={ValidateStage}>
                    <ValidateStage />
                  </Route>
                  <Route exact path="/completed" component={Completed}>
                    <Completed />
                  </Route>
                  <Route exact path="/products" component={Products}>
                    <Products />
                  </Route>
                  <Route exact path="/about" component={About}>
                    <About />
                  </Route>
                </Switch>
              </div>
            </Router>
          </>
        ) : (
          <AmplifySignOut />
        )}
      </div>
    </AmplifyAuthenticator>
  );
}

export default App;
