import { useEffect, useState } from "react";
import { API } from "aws-amplify";

function useLoadAccount() {
  const [product, setGetProduct] = useState({
    items: null,
    loading: true,
  });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const DataloadData = await API.get("dataloadsapi", "/accounts");
      console.log("Products:", DataloadData);
      setGetProduct({ accountitems: DataloadData.Items, loading: false });
    } catch (err) {
      setGetProduct({ accountitems: {}, loading: true });
      console.log("error fetching dataloads...", err);
    }
  }

  return product;
}

export default useLoadAccount;
