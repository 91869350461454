import React, { useEffect, useState } from "react";
import ProductsTable from "./ProductsTable";
import { API } from "aws-amplify";

function Products() {
  const [dataloads, updateProducts] = useState([]);
  const [enablerefresh, setEnableRefresh] = useState(false);

  useEffect(() => {
    if (enablerefresh) {
      const interval = setInterval(() => {
        getData();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      getData();
      setEnableRefresh(true);
    }
  }, [enablerefresh]);

  async function getData() {
    try {
      const DataloadData = await API.get("dataloadsapi", "/products");
      console.log("DataloadData:", DataloadData);
      updateProducts(DataloadData.Items);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  return <ProductsTable data={dataloads} />;
}

export default Products;
