import React from "react";
import ItemForm from "./ItemForm";
import { Redirect } from "react-router";
import logo from "./NomisLogomark.png";
import { Image, Container, Row, Form, Button } from "react-bootstrap";

const PLParams = ({ datarecords, setForm, formData, navigation }) => {
  // Redirecting home if status is not New
  if (datarecords && datarecords.DataStatus !== "100") {
    return <Redirect to="/inprogress" />;
  } else {
    const { pl_startdate, pl_enddate } = formData;

    const { next } = navigation;

    return (
      <div>
        <br />
        <br />
        <Container fluid>
          <Row className="justify-content-md-center">
            <Image src={logo} style={{ width: "20%" }} />
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Form>
              <ItemForm
                label="PriceList Start Date:"
                name="pl_startdate"
                value={pl_startdate}
                onChange={setForm}
              />
              <ItemForm
                label="PriceList End Date:"
                name="pl_enddate"
                value={pl_enddate}
                onChange={setForm}
              />
              <br />
              <Button size="lg" onClick={next} variant="primary" type="button">
                Next
              </Button>
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
};

export default PLParams;
