import React, { useState } from "react";
import { useForm, useStep } from "react-hooks-helper";
import "bootstrap/dist/css/bootstrap.min.css";
import SubmitProduct from "./SubmitProduct";
import ProductParams from "./ProductParams";

const steps = [{ id: "productparams" }, { id: "submitproduct" }];

function ProductEdit({ records }) {
  const defaultData = {
    product_url: records.URL,
    business_type: records.BusinessType,
    bank_name: records.BankShortName,
    transaction_version: records.TransactionVersion,
  };

  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = {
    records,
    formData,
    setForm,
    navigation,
  };

  switch (id) {
    case "productparams":
      return <ProductParams {...props} />;
    case "submitproduct":
      return <SubmitProduct {...props} />;
    default:
      return null;
  }
}

export default ProductEdit;
