import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";

function NavBar() {
  function signOut() {
    Auth.signOut().catch((err) => console.log("error signing out: ", err));
  }
  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <LinkContainer to="/">
        <Navbar.Brand href="#Home">Nomis-Dataloads</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link href="#Home">New Dataloads</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/inprogress">
            <Nav.Link href="#inprogress">Progress</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/completed">
            <Nav.Link href="#completed">Completed</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/products">
            <Nav.Link href="#products">Products</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/about">
            <Nav.Link href="#about">About</Nav.Link>
          </LinkContainer>
        </Nav>
        <Button variant="outline-light" onClick={signOut}>
          Sign Out
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
