import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useRestartDataload = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const transaction_id = params.get("id") || "";
  const url = params.get("url") || "";

  const [restart, updateRestart] = useState(null);

  async function getData(transaction_id) {
    console.log("transaction:", transaction_id);
    try {
      const RestartResponse = await API.post("dataloadsapi", "/restart", {
        body: {
          DataID: transaction_id,
          ProdUrl: url,
        },
      });
      updateRestart(RestartResponse.Items);
      console.log("Data Records:", RestartResponse.Items);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  useEffect(() => {
    getData(transaction_id);
    return () => {
      console.log("unmount");
    };
  }, [transaction_id]);

  return { restart };
};
