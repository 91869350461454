import React from "react";
import { withRouter } from "react-router";
import InstancesAlert from "./InstancesAlert";
import ShowInstances from "./ShowInstances";
import { Container, Row, Col } from "react-bootstrap";

const RetryCodeDeploy = ({
  running_instances,
  stopped_instances,
  datarecords,
  formData,
  setForm,
  navigation,
}) => {
  return (
    <div>
      <br />
      <div>
        <>
          <Container>
            <Row>
              <Col>
                <InstancesAlert
                  running_instances={running_instances}
                  stopped_instances={stopped_instances}
                />
              </Col>
            </Row>
            <Row>
              <Col md="auto">
                <ShowInstances
                  navigation={navigation}
                  datarecords={datarecords}
                  formData={formData}
                  setForm={setForm}
                  running_instances={running_instances}
                  stopped_instances={stopped_instances}
                />
              </Col>
            </Row>
          </Container>
        </>
      </div>
      <br />
    </div>
  );
};

export default withRouter(RetryCodeDeploy);
