import React from "react";
import { Redirect, withRouter } from "react-router";
import logo from "./NomisLogomark.png";
import {
  ListGroup,
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";

const GlobalInputInfo = ({ datarecords, navigation }) => {
  // Redirecting home if status is not New
  if (datarecords && datarecords.DataStatus !== "100") {
    return <Redirect to="/inprogress" />;
  }

  const { previous, next } = navigation;

  const test = datarecords.s3Key;

  return (
    <div>
      <br />
      <Container fluid>
        <Row className="justify-content-md-center">
          <Image src={logo} style={{ width: "20%" }} />
        </Row>
        <br />
        <Row className="justify-content-md-center">
          <Col xs lg="7">
            <Row className="justify-content-md-center">
              <Col md="auto">
                <h4>Data Info</h4>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <ListGroup>
                <ListGroup.Item variant="light">
                  Application Name: {datarecords.AppName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Customer: {datarecords.BankShortName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Prod URL: {datarecords.ProdUrl}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  S3 Bucket: {datarecords.dataBucket}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  S3 Files:
                  {datarecords.s3Key.map((datafile) => (
                    <>
                      <br />- {datafile}
                    </>
                  ))}
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Form size="sm">
                <Button
                  size="lg"
                  onClick={next}
                  variant="primary"
                  type="button"
                >
                  Confirm
                </Button>
              </Form>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GlobalInputInfo;
