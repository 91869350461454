import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useForm, useStep } from "react-hooks-helper";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalInputInfo from "./GlobalInputInfo";
import GiParams from "./GiParams";
import CreateRPM from "./CreateRPM";
import Submit from "./Submit";
import CodeDeployRun from "./CodeDeployRun";
import { useFetch } from "./useFetch";
import { Auth } from "aws-amplify";

const steps = [
  { id: "giparams" },
  { id: "datainfo" },
  { id: "createrpm" },
  { id: "codedeployrun" },
  { id: "submit" },
];

const defaultData = {
  tlp_gi_name: "GI_Name_0001",
  truncate_table: false,
  regression_name: "Regression_001",
  instance_selected: "",
  gi_startdate: "01-Jan-2020",
  gi_enddate: "01-Jan-2020",
  transaction_startdate: "01-Jan-2020",
  transaction_enddate: "01-Jan-2020",
  pl_startdate: "01-Jan-2020",
  pl_enddate: "01-Jan-2020",
};

function GlobalInput() {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const [refresh, setRefresh] = useState(true);

  const datarecords = useFetch(setRefresh, refresh);

  const [userdetails, setUserDetails] = useState(null);

  useEffect(() => {
    getDetails();
  }, []);

  async function getDetails() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      setUserDetails(attributes);
      console.log("User Details: ", attributes);
    } catch (err) {
      console.log("Error: ", err);
    }
  }

  const props = {
    datarecords,
    formData,
    setForm,
    navigation,
    setRefresh,
    refresh,
  };

  switch (id) {
    case "giparams":
      return <GiParams {...props} />;
    case "datainfo":
      return <GlobalInputInfo {...props} />;
    case "createrpm":
      return <CreateRPM {...props} userdetails={userdetails} />;
    case "codedeployrun":
      return <CodeDeployRun {...props} />;
    case "submit":
      return <Submit {...props} />;
    default:
      return null;
  }
}

export default withRouter(GlobalInput);
