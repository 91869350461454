import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function InstancesAlert({ running_instances, stopped_instances }) {
  function RunningInstancesAlert() {
    return (
      <Alert
        key="running_instances_alert"
        variant="primary"
        onClose={() => setShowAlert(false)}
        dismissible
      >
        Instances Found!
      </Alert>
    );
  }

  function StoppedInstancesAlert() {
    return (
      <Alert
        key="stopped_instances_alert"
        variant="warning"
        onClose={() => setShowAlert(false)}
        dismissible
      >
        Not Running Instances Found!
      </Alert>
    );
  }

  function NoInstances() {
    return (
      <Alert key="no_instances" variant="danger">
        Instances not Found!
      </Alert>
    );
  }

  /*console.log("running_instances: ", running_instances);*/
  /*console.log("stopped_instances: ", stopped_instances);*/
  /*console.log("running_instances length: ", running_instances.length);*/
  /*console.log("stopped_instances length: ", stopped_instances.length);*/

  const [showalert, setShowAlert] = useState(true);

  if (showalert) {
    if (running_instances.length > 0) {
      return <RunningInstancesAlert />;
    } else if (stopped_instances.length > 0) {
      return <StoppedInstancesAlert />;
    } else {
      return <NoInstances />;
    }
  }
  return null;
}

export default InstancesAlert;
