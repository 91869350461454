import { useEffect, useState } from "react";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../../aws-exports";
Amplify.configure(awsconfig);

export const useNewSubmitProduct = ({ formData }) => {
  const [restart, updateRestart] = useState(null);
  const [codestatus, setCodestatus] = useState({ status: null, loading: true });
  const {
    product_url,
    vertical,
    business_type,
    transaction_version,
    bank_name,
    account_type,
  } = formData;

  async function setPermissions({ loading }) {
    if (!loading) {
      const permissionResults = await API.post(
        "dataloadsapi",
        "/rpmpermissions",
        {
          body: {
            ACCOUNT_ID: restart.ACCOUNT_ID,
            REGION: restart.REGION,
            application: restart.application,
            application1: restart.application1,
            application2: restart.application2,
            application3: restart.application3,
          },
        }
      );
      console.log("Permission Results:", permissionResults);
    }
  }

  async function getStatus(restart) {
    if (restart && restart !== "Succeeded") {
      const ReportStatus = await API.post("dataloadsapi", "/codedeploystatus", {
        body: {
          accountId: restart.ACCOUNT_ID,
          Region: restart.REGION,
          deploymentId: restart.deploymentId,
        },
      });
      console.log("CodeDeploy Status:", ReportStatus);
      if (ReportStatus.Items !== "Succeeded") {
        setCodestatus({ status: "None", loading: true });
      } else {
        setCodestatus({ status: ReportStatus.Items, loading: false });
      }
    }
  }

  async function getData() {
    console.log("New transaction:", transaction_version);
    try {
      const RestartResponse = await API.post("dataloadsapi", "/newproduct", {
        body: {
          PROD_URL: product_url,
          BankName: bank_name,
          BusinessType: business_type,
          TransactionVersion: transaction_version,
          AccountType: account_type,
          vertical: vertical,
        },
      });
      updateRestart(RestartResponse.Items);
      console.log("New Data Records:", RestartResponse.Items);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  useEffect(() => {
    getData();
    return () => {
      console.log("unmount");
    };
  }, []);

  useEffect(() => {
    getStatus(restart);
  });

  useEffect(() => {
    setPermissions(codestatus);
  });

  return codestatus;
};
