import React, { useState } from "react";
import { useForm, useStep } from "react-hooks-helper";
import RetryCodeDeploy from "./RetryCodeDeploy";
import { useCodeDeploy } from "./useCodeDeploy";
import CodeDeployRun from "./CodeDeployRun";
import Loading from "./Loading";

const defaultData = {
  instance_selected: "",
};

function PreAction() {
  const [formData, setForm] = useForm(defaultData);
  const [refresh, setRefresh] = useState(true);

  const steps = [{ id: "retrycodedeploy" }, { id: "codedeployrun" }];

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const { datarecords, running_instances, stopped_instances } = useCodeDeploy(
    setRefresh,
    refresh
  );

  const props = {
    datarecords,
    formData,
    setForm,
    navigation,
    setRefresh,
    refresh,
    running_instances,
    stopped_instances,
  };

  switch (id) {
    case "retrycodedeploy":
      return (
        <div>{datarecords ? <RetryCodeDeploy {...props} /> : <Loading />}</div>
      );
    case "codedeployrun":
      return <CodeDeployRun {...props} />;
    /*case "review":*/
    /*  return <Review {...props} />;*/
    /*case "createrpm":*/
    /*  return <CreateRPM {...props} />; */
    /*case "submit":*/
    /*  return <Submit {...props} />;*/
    default:
      return null;
  }
}

export default PreAction;
