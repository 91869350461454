import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

function ProgressTable({ setForm, formData, rpmdata }) {
  const ButtonSelection = (props) => {
    const { choice_selected } = formData;
    function Choices({ page, label, id, url }) {
      return (
        <>
          <Form.Check
            type="radio"
            checked={choice_selected === `restart_dataload?id=${id}&url=${url}`}
            onChange={setForm}
            label="Restart Dataload"
            value={`restart_dataload?id=${id}&url=${url}`}
            name="choice_selected"
            key={`RestartDataload_${id}`}
          />
          <Form.Check
            type="radio"
            checked={choice_selected === `${page}?id=${id}`}
            onChange={setForm}
            label={label}
            value={`${page}?id=${id}`}
            name="choice_selected"
            key={id}
          />
        </>
      );
    }
    const { action, label, dataid, url } = props;

    switch (action) {
      case "RetryCodeDeploy":
        return <Button href={`/preaction?id=${dataid}`}>{label}</Button>;
      case "ReadyforCodeDeploy":
        return (
          <>
            <Form>
              <Choices page="preaction" label={label} id={dataid} url={url} />
              <Button href={`/${choice_selected}`}>Submit</Button>
            </Form>
          </>
        );
      case "StartProduction":
        return (
          <Button size="sm" href={`/preaction?id=${dataid}`}>
            {label}
          </Button>
        );
      case "readyforvalidation":
        return (
          <>
            <Form>
              <Choices
                page="validate_stage"
                label={label}
                id={dataid}
                url={url}
              />
              <Button href={`/${choice_selected}`}>Submit</Button>
            </Form>
          </>
        );
      case "DataloadCompleted":
        return (
          <Button size="md" variant="primary" disabled>
            {" "}
            {label}
          </Button>
        );
      default:
        return (
          <Button size="md" variant="primary" disabled>
            {" "}
            In Progress ..
          </Button>
        );
    }
  };

  const renderComponent = (component, index) => {
    const props = {
      action: component.NextAction,
      label: component.NextActionLabel,
      dataid: component.DataID,
      url: component.ProdUrl,
    };

    if (component.DataStatus !== "700") {
      if (component.TargetInstance) {
        const uri = `https://service.us2.sumologic.com/ui/#/search/@@_collector=${component.TargetInstance} AND _source=npo_run_log`;
        const encoded = encodeURI(uri);
        return (
          <tr style={{ fontSize: 14 }} key={component.DataID}>
            <td>
              <ButtonSelection {...props} />
            </td>
            <td>
              <Link to={`/viewdata?id=${component.DataID}`}>
                {component.DataVersion}
              </Link>
            </td>
            <td>
              {component.TargetInstance}
              <br />
              Account ID: {component.AwsAccountId}
            </td>
            <td>{component.BankShortName}</td>
            <td>{component.DataType}</td>
            <td>
              {component.StatusDescription}
              <br />
              <a
                style={{ display: "table-cell" }}
                href={encoded}
                target="_blank"
              >
                SumoLogic logs
              </a>
            </td>
            <td>{component.EventDateTime}</td>
          </tr>
        );
      } else {
        return (
          <tr style={{ fontSize: 14 }} key={component.DataID}>
            <td>
              <ButtonSelection {...props} />
            </td>
            <td>
              <Link to={`/viewdata?id=${component.DataID}`}>
                {component.DataVersion}
              </Link>
            </td>
            <td>{component.TargetInstance}</td>
            <td>{component.BankShortName}</td>
            <td>{component.DataType}</td>
            <td>{component.StatusDescription}</td>
            <td>{component.EventDateTime}</td>
          </tr>
        );
      }
    }
  };
  // Render the UI for your table
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr style={{ fontSize: 15 }}>
          <th>Dataload Action</th>
          <th>Dataload Version</th>
          <th>Target Instance</th>
          <th>Customer</th>
          <th>Type</th>
          <th>Status Description</th>
          <th>Data Date</th>
        </tr>
      </thead>
      <tbody>{rpmdata.map(renderComponent)}</tbody>
    </Table>
  );
}

export default ProgressTable;
