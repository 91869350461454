import React from "react";
import useLoadProduct from "./useLoadProduct";
import ProductEdit from "./products/ProductEdit";
import Loading from "./Loading";
import { Container, Row, Col } from "react-bootstrap";

const LoadProduct = () => {
  const { productitems, loading } = useLoadProduct();

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <ProductEdit records={productitems[0]} />;
  }

  // console.log("CodeDeploy form: ", formData);
  // console.log("Data Records: ", datarecords);
};

export default LoadProduct;
