import React from "react";
import { Table, Button, Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function ProductsTable({ data }) {
  const renderComponent = (component, index) => {
    return (
      <tr key={component.AppName}>
        <td>{index + 1}</td>
        <td>
          <Link to={`/loadproduct?id=${component.URL}`}>{component.URL}</Link>
        </td>
        <td>{component.BankShortName}</td>
        <td>{component.vertical}</td>
        <td>{component.BusinessType}</td>
        <td>{component.TransactionVersion}</td>
      </tr>
    );
  };

  // Render the UI for your table
  return (
    <Container>
      <br />
      <Row>
        <Col>
          <Button href="/loadaccount" variant="outline-primary">
            New Product
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Production URL</th>
            <th>Customer</th>
            <th>Vertical</th>
            <th>Business Type</th>
            <th>Transaction Type</th>
          </tr>
        </thead>
        <tbody>{data.map(renderComponent)}</tbody>
      </Table>
    </Container>
  );
}

export default ProductsTable;
