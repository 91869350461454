import { useEffect, useState } from "react";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useFetchInstances = (data) => {
  const [running_instances, setRunningInstances] = useState(null);
  const [stopped_instances, setStoppedInstances] = useState(null);

  async function getInstance() {
    try {
      const InstanceDetails = await API.get("dataloadsapi", "/instances", {
        queryStringParameters: {
          product: "npo",
          inst_env: "stage",
          status: data.DataStatus,
          accountId: data.AwsAccountId,
          region: data.Region,
          vertical: data.Vertical,
          BusinessType: data.BusinessType,
        },
      });
      console.log("Instance Details: ", InstanceDetails);
      setRunningInstances(InstanceDetails["Items"]);
    } catch (err) {
      console.log("error fetching instance details...", err);
    }
    try {
      const InstanceStoppedDetails = await API.get(
        "dataloadsapi",
        "/instances",
        {
          queryStringParameters: {
            product: "npo",
            inst_env: "stage",
            inst_state: "stopped",
            status: data.DataStatus,
            accountId: data.AwsAccountId,
            region: data.Region,
            vertical: data.Vertical,
            BusinessType: data.BusinessType,
          },
        }
      );
      console.log("Instance Stopped Details: ", InstanceStoppedDetails);
      setStoppedInstances(InstanceStoppedDetails["Items"]);
    } catch (err) {
      console.log("error fetching instance details...", err);
    }
  }

  useEffect(() => {
    getInstance();
    return () => {
      console.log("Instances unmount!!!");
    };
  }, []);

  return {
    running_instances: running_instances,
    stopped_instances: stopped_instances,
  };
};
