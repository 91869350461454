import React from "react";
import ItemForm from "./ItemForm";
import { Redirect } from "react-router";
import logo from "./NomisLogomark.png";
import { Image, Container, Row, Form, Button } from "react-bootstrap";

const TransactionV1 = ({ datarecords, setForm, formData, navigation }) => {
  // Redirecting home if status is not New
  if (datarecords && datarecords.DataStatus !== "100") {
    return <Redirect to="/inprogress" />;
  } else {
    const {
      tlp_gi_name,
      truncate_table,
      regression_name,
      transaction_startdate,
      transaction_enddate,
    } = formData;

    const { next } = navigation;

    return (
      <div>
        <br />
        <br />
        <Container fluid>
          <Row className="justify-content-md-center">
            <Image src={logo} style={{ width: "20%" }} />
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Form>
              <Form.Group controlId="formBasicTruncate">
                <Form.Check
                  type="checkbox"
                  defaultChecked={truncate_table}
                  name="truncate_table"
                  onChange={setForm}
                  label="Truncate ns_hist_transaction_detail Table?"
                />
              </Form.Group>
              <ItemForm
                label="TLP / Global Input Name:"
                name="tlp_gi_name"
                value={tlp_gi_name}
                onChange={setForm}
              />
              <ItemForm
                label="Regression Name:"
                name="regression_name"
                value={regression_name}
                onChange={setForm}
              />
              <ItemForm
                label="Transaction Start Date:"
                name="transaction_startdate"
                value={transaction_startdate}
                onChange={setForm}
              />
              <ItemForm
                label="Transaction End Date:"
                name="transaction_enddate"
                value={transaction_enddate}
                onChange={setForm}
              />
              <br />
              <Button size="lg" onClick={next} variant="primary" type="button">
                Next
              </Button>
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
};

export default TransactionV1;
