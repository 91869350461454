import React from "react";
import logo from "./NomisLogomark.png";
import {
  ListGroup,
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";

const DataInfo = ({ datarecords, navigation }) => {
  // Redirecting home if status is not New
  const { previous, next } = navigation;

  return (
    <div>
      <br />
      <Container fluid>
        <Row className="justify-content-md-center">
          <Image src={logo} style={{ width: "20%" }} />
        </Row>
        <br />
        <Row className="justify-content-md-center">
          <Col xs lg="7">
            <Row className="justify-content-md-center">
              <Col md="auto">
                <h4>Data Info</h4>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <ListGroup>
                <ListGroup.Item variant="light">
                  Customer: {datarecords.BankShortName.toUpperCase()}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  S3 Bucket: {datarecords.dataBucket}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  S3 Files: {datarecords.s3Key}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Data Event Date: {datarecords.EventDateTime}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Instance: {datarecords.TargetInstance}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  URL: {datarecords.ProdUrl}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Region: {datarecords.Region}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Vertical: {datarecords.Vertical}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Business: {datarecords.BusinessType}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  RPM: {datarecords.CurrentRPM}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Transaction File: {datarecords.TransactionFile}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Transaction Start Date: {datarecords.TransactionStartDate}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Transaction End Date: {datarecords.TransactionEndDate}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Aggregation Name: {datarecords.AggregationName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Aggregation Start Date: {datarecords.AggregationStartDate}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Aggregation End Date: {datarecords.AggregationEndDate}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  TLP Regression Name: {datarecords.TLPRegressionDefinitionName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  TLP Global Input Name:{" "}
                  {datarecords.TLPGlobalInputDefinitionName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Global Input Name: {datarecords.GlobalInputDataName}
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Form size="sm">
                <Button
                  size="lg"
                  onClick={next}
                  variant="primary"
                  type="button"
                >
                  Go Back
                </Button>
              </Form>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DataInfo;
