/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id:
    "us-west-2:6bea7d48-21ef-4c50-bec0-604e10037781",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_4j6VUqHBI",
  aws_user_pools_web_client_id: "64d4tvfb9o6ue4mpmhi9j1ui5h",
  oauth: {
    domain: "npodataloads-prod.auth.us-west-2.amazoncognito.com",
    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://npodataloads.nomisinternal.com/",
    redirectSignOut: "https://npodataloads.nomisinternal.com/",
    responseType: "token",
  },
  aws_dynamodb_all_tables_region: "us-west-2",
  aws_dynamodb_table_schemas: [
    {
      tableName: "dataloads-records-prod",
      region: "us-west-2",
    },
    {
      tableName: "dataload-status-prod",
      region: "us-west-2",
    },
  ],
  aws_user_files_s3_bucket: "nomis-artifacts-logs03006-prod",
  aws_user_files_s3_bucket_region: "us-west-2",
  aws_cloud_logic_custom: [
    {
      name: "dataloadsapi",
      endpoint: "https://kvdiq9kyfi.execute-api.us-west-2.amazonaws.com/prod",
      region: "us-west-2",
    },
  ],
  aws_content_delivery_bucket:
    "dataloadsfrontend-20200731181605-hostingbucket-prod",
  aws_content_delivery_bucket_region: "us-west-2",
  aws_content_delivery_url: "https://d3g5pujd8w48tn.cloudfront.net",
};

export default awsmobile;
