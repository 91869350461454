import React from "react";
import { useCodeDeployRun } from "./useCodeDeployRun";
import { Redirect } from "react-router";
import Loading from "./Loading";
import { Container, Row, Col } from "react-bootstrap";

const CodeDeployRun = ({ datarecords, formData }) => {
  const { running } = useCodeDeployRun(datarecords, formData);

  if (running) {
    return (
      <Container>
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <Redirect to="/inprogress" />;
  }

  // console.log("CodeDeploy form: ", formData);
  // console.log("Data Records: ", datarecords);
};

export default CodeDeployRun;
