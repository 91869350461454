import { useEffect, useState } from "react";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useCodeDeployRun = (datarecords, formData) => {
  const [codeDeployResults, setCodedeployResults] = useState({
    items: null,
    running: true,
  });

  const { instance_ip, instance_selected } = formData;

  const {
    AwsAccountId,
    DataID,
    ProdUrl,
    CodeDeployAppName,
    CodeDeployAppVersion,
    Region,
  } = datarecords;

  const myParameters = {
    body: {
      AwsAccountId: AwsAccountId,
      DataID: DataID,
      ProdUrl: ProdUrl,
      CodeDeployAppName: CodeDeployAppName,
      CodeDeployAppVersion: CodeDeployAppVersion,
      Region: Region,
      instanceName: instance_selected,
    },
  };
  console.log("My parameters: ", myParameters);

  useEffect(() => {
    console.log("Parameters: ", myParameters);
    async function callCodeDeploy() {
      try {
        const codeDeployItems = await API.post(
          "dataloadsapi",
          "/codedeploycall",
          myParameters
        );
        setCodedeployResults({ items: codeDeployItems.Items, running: false });
        console.log("CodeDeploy Call:", codeDeployItems);
      } catch (err) {
        console.log(err);
      }
    }
    callCodeDeploy();
  }, []);

  return codeDeployResults;
};
