import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";

function useLoadProduct() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const product_url = params.get("id") || "";
  const [product, setGetProduct] = useState({
    items: null,
    loading: true,
  });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const DataloadData = await API.get("dataloadsapi", "/products", {
        queryStringParameters: {
          ProductURL: product_url,
        },
      });
      console.log("Products:", DataloadData);
      setGetProduct({ productitems: DataloadData.Items, loading: false });
    } catch (err) {
      setGetProduct({ productitems: {}, loading: true });
      console.log("error fetching dataloads...", err);
    }
  }

  return product;
}

export default useLoadProduct;
