import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ItemForm = ({ label, children, type = "text", ...otherProps }) => (
  <Form.Group as={Row} controlId={otherProps["name"]}>
    <Form.Label column md="auto">
      {label}
    </Form.Label>
    <Col sm={10}>
      <Form.Control type={type} {...otherProps} />
    </Col>
  </Form.Group>
);

export default ItemForm;
