import React from "react";
import { Redirect, withRouter } from "react-router";
import logo from "./NomisLogomark.png";
import {
  ListGroup,
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";

const Review = ({ datarecords, setForm, formData, navigation }) => {
  // Redirecting home if status is not New
  if (datarecords && datarecords.DataStatus !== "100") {
    return <Redirect to="/inprogress" />;
  }

  const {
    tlp_gi_name,
    truncate_table,
    regression_name,
    transaction_startdate,
    transaction_enddate,
  } = formData;

  const { previous, next } = navigation;

  return (
    <div>
      <br />
      <Container fluid>
        <Row className="justify-content-md-center">
          <Image src={logo} style={{ width: "20%" }} />
        </Row>
        <br />
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Row className="justify-content-md-center">
              <Col md="auto">
                <Button
                  onClick={previous}
                  variant="outline-primary"
                  type="submit"
                >
                  Edit
                </Button>
              </Col>
              <Col sm={8}>
                <h4>Dataload Inputs</h4>
              </Col>
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Col>
                <Form size="sm">
                  <Form.Group controlId="formBasicTruncate">
                    <Form.Check
                      type="checkbox"
                      defaultChecked={truncate_table}
                      name="truncate_table"
                      label="Truncate ns_hist_transaction_detail Table?"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextGIName">
                    <Form.Label column md="auto">
                      TLP / Global Input Name:
                    </Form.Label>
                    <Col md="auto">
                      <Form.Control
                        size="sm"
                        plaintext
                        readOnly
                        placeholder={`${tlp_gi_name}`}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextRegName">
                    <Form.Label column md="auto">
                      Regression
                    </Form.Label>
                    <Col md="auto">
                      <Form.Control
                        size="sm"
                        plaintext
                        readOnly
                        placeholder={`${regression_name}`}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextStartDate">
                    <Form.Label column md="auto">
                      Transaction Start Date
                    </Form.Label>
                    <Col md="auto">
                      <Form.Control
                        size="sm"
                        plaintext
                        readOnly
                        placeholder={`${transaction_startdate}`}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEndDate">
                    <Form.Label column md="auto">
                      Transaction End Date
                    </Form.Label>
                    <Col md="auto">
                      <Form.Control
                        size="sm"
                        plaintext
                        readOnly
                        placeholder={`${transaction_enddate}`}
                      />
                    </Col>
                  </Form.Group>
                  <br />
                  <br />
                  <Button
                    size="lg"
                    onClick={next}
                    variant="primary"
                    type="button"
                  >
                    Confirm
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col xs lg="5">
            <Row className="justify-content-md-center">
              <Col md="auto">
                <h4>Data Info</h4>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <ListGroup>
                <ListGroup.Item variant="light">
                  Application Name: {datarecords.AppName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Customer: {datarecords.BankShortName}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  Prod URL: {datarecords.ProdUrl}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  S3 Bucket: {datarecords.dataBucket}
                </ListGroup.Item>
                <ListGroup.Item variant="light">
                  S3 Path: {datarecords.s3Key}
                </ListGroup.Item>
              </ListGroup>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Review;
