import React from "react";
import * as ReactBoostrap from "react-bootstrap";

function Table({ data }) {
  function DataFiles({ component }) {
    if (component.DataType === "global-assumption") {
      return (
        <td>
          {component.datafiles.map((datafile) => (
            <>
              {datafile}
              <br />
            </>
          ))}
        </td>
      );
    } else {
      return <td>{component.datafiles}</td>;
    }
  }

  const renderComponent = (component, index) => {
    return (
      <tr key={index}>
        <td>
          <ReactBoostrap.Button
            href={`/${component.DataType}?id=${component.DataID}`}
            variant="primary"
            block
          >
            Start Dataload
          </ReactBoostrap.Button>
        </td>
        <td>{component.BankShortName.toUpperCase()}</td>
        <td>{component.DataType}</td>
        <DataFiles component={component} />
        <td>{component.ProdUrl}</td>
        <td>{component.Vertical}</td>
        <td>{component.BusinessType}</td>
        <td>{component.EventDateTime}</td>
      </tr>
    );
  };

  // Render the UI for your table
  return (
    <ReactBoostrap.Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Action</th>
          <th>Customer</th>
          <th>Dataload Type</th>
          <th>Files</th>
          <th>URL</th>
          <th>Vertical</th>
          <th>Business Type</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>{data.map(renderComponent)}</tbody>
    </ReactBoostrap.Table>
  );
}

export default Table;
