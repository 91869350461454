import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useFetch = (setRefresh, refresh) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const transaction_id = params.get("id") || "";

  const [datarecords, updateDataload] = useState(null);

  async function getData(transaction_id) {
    console.log("transaction:", transaction_id);
    try {
      const DataloadRecord = await API.get("dataloadsapi", "/dataload", {
        queryStringParameters: {
          DataID: transaction_id,
        },
      });
      updateDataload(DataloadRecord.Items);
      console.log("Data Records:", DataloadRecord.Items);
      setRefresh(false);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  useEffect(() => {
    console.log("Refresh: ", refresh);
    getData(transaction_id);
    return () => {
      console.log("unmount");
    };
  }, [transaction_id, refresh]);

  return datarecords;
};
