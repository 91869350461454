import React, { useEffect, useState } from "react";
import { Spinner, Container, Row, Col } from "react-bootstrap";

function Loading() {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col md="auto">
            Building ...&nbsp;&nbsp;
            <br />
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only"> Loading...</span>
            </Spinner>
            <br />
            Build time: {seconds}s
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Loading;
