import React, { useState } from "react";
import { useForm, useStep } from "react-hooks-helper";
import "bootstrap/dist/css/bootstrap.min.css";
import NewSubmitProduct from "./NewSubmitProduct";
import NewProductParams from "./NewProductParams";

const steps = [{ id: "productparams" }, { id: "submitproduct" }];

function NewProduct({ records }) {
  const defaultData = {
    product_url: "http://",
    vertical: "deposits",
    business_type: "consumer",
    bank_name: "",
    account_type: "gen3",
    transaction_version: "v1",
  };

  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = {
    records,
    formData,
    setForm,
    navigation,
  };

  switch (id) {
    case "productparams":
      return <NewProductParams {...props} />;
    case "submitproduct":
      return <NewSubmitProduct {...props} />;
    default:
      return null;
  }
}

export default NewProduct;
