import React from "react";
import { Card } from "react-bootstrap";

function ResultsTable({ data, formData, time }) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const { tlp_gi_name, regression_name } = formData;

  console.log("Data RPM records: ", data);

  function TransactionReport() {
    return (
      <>
        <b> Transaction Name:</b> {data.TransactionDataName}
        <br />
        <b> Transaction File:</b> {data.TransactionFile}
        <br />
        <b> Transaction Start Date:</b> {data.TransactionStartDate}
        <br />
        <b> Transaction End Date:</b> {data.TransactionEndDate}
        <br />
        <b> Global Input:</b> {tlp_gi_name}
        <br />
        <b> Regression Name:</b> {regression_name}
        <br />
        <b>Source File:</b> s3://{data.dataBucket}/{data.s3Key}
      </>
    );
  }

  function PriceListReport() {
    return (
      <>
        <b> PriceList Name:</b> {data.PriceListName}
        <br />
        <b> PriceList Start Date:</b> {data.PriceListStartDate}
        <br />
        <b> PriceList End Date:</b> {data.PriceListEndDate}
        <br />
        <b>Source File:</b> s3://{data.dataBucket}/{data.s3Key}
      </>
    );
  }

  function GlobalInputReport() {
    return (
      <>
        <b> Global Input Name:</b> {data.GlobalInputDataName}
        <br />
        <b> Global Input Start Date:</b> {data.GlobalInputStartDate}
        <br />
        <b> Global Input End Date:</b> {data.GlobalInputEndDate}
        <br />
        <b>S3 Files: </b>
        {data.s3Key.map((datafile) => (
          <>
            <br />- {datafile}
          </>
        ))}
      </>
    );
  }

  let report;

  if (data.DataType.includes("transaction")) {
    report = <TransactionReport />;
  }

  if (data.DataType === "pricelist") {
    report = <PriceListReport />;
  }

  if (data.DataType === "global-assumption") {
    report = <GlobalInputReport />;
  }

  return (
    <>
      <Card style={{ width: "38rem" }}>
        <Card.Body>
          <Card.Title>RPM created</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {" "}
            Build time: {minutes}m {seconds}s
          </Card.Subtitle>
          <b>RPM:</b>
          <a href={data.rpmSignedUrl}> {data.CurrentRPM}</a>
          <br />
          {report}
          <br />
        </Card.Body>
      </Card>
    </>
  );
}

export default ResultsTable;
