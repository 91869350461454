import React, { useEffect, useState } from "react";
import Table from "./Table.js";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

Amplify.configure(awsconfig);

function Home({ user }) {
  const [dataloads, updateHome] = useState([]);
  const [enablerefresh, setEnableRefresh] = useState(false);

  //console.log("User data in Home:", user);

  useEffect(() => {
    if (enablerefresh) {
      const interval = setInterval(() => {
        getData();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      getData();
      setEnableRefresh(true);
    }
  }, [enablerefresh]);

  async function getData() {
    try {
      const DataloadData = await API.get("dataloadsapi", "/records", {
        queryStringParameters: {
          status: "100",
        },
      });
      console.log("DataloadData:", DataloadData);
      updateHome(DataloadData.Items);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  if (dataloads.length > 0) {
    return <Table data={dataloads} />;
  } else {
    return (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        &nbsp;&nbsp;Not new data found...
      </Button>
    );
  }
}

export default Home;
