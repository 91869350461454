import React, { useState } from "react";
import { withRouter } from "react-router";
import { Col, Form, Button } from "react-bootstrap";

function ShowInstances({
  formData,
  setForm,
  navigation,
  running_instances,
  stopped_instances,
}) {
  const { previous, next } = navigation;
  const { instance_selected } = formData;

  function RunningInstances() {
    return (
      <>
        {running_instances.map((instance) => (
          <Form.Check
            type="radio"
            checked={instance_selected === instance.Name}
            onChange={setForm}
            label={instance.Name}
            value={instance.Name}
            name="instance_selected"
            key={instance.InstanceId}
          />
        ))}
      </>
    );
  }

  function StoppedInstances() {
    const [stopped_instance, setStoppedInstance] = useState("");
    return (
      <>
        {stopped_instances.map((instance) => (
          <Form.Check
            type="radio"
            checked={stopped_instance === instance.InstanceId}
            onChange={() => setStoppedInstance(instance.InstanceId)}
            value={instance.InstanceId}
            label={instance.Name}
            name="SelectedInstance"
            id={instance.InstanceId}
          />
        ))}
      </>
    );
  }

  let instances;
  let form_title;
  let button;

  console.log("running_instances: ", running_instances);
  console.log("stopped_instances: ", stopped_instances);
  console.log("Next: ", next);

  if (running_instances.length > 0) {
    form_title = "Running Instances: ";
    button = (
      <Button onClick={next} type="button">
        Apply Dataload
      </Button>
    );
    instances = <RunningInstances />;
  } else if (stopped_instances.length > 0) {
    form_title = "Start Instance to continue: ";
    button = <Button type="button">Start Instance</Button>;
    instances = <StoppedInstances />;
  } else {
    form_title = "Instances not found!";
    button = null;
    instances = null;
  }

  return (
    <Form>
      <Form.Row>
        <fieldset>
          <Form.Group
            as={Col}
            name="SelectedInstance"
            controlId="SelectedInstance"
          >
            <Form.Label as="legend" xs="auto">
              {form_title}
            </Form.Label>
            {instances}
          </Form.Group>
        </fieldset>
      </Form.Row>
      {button}
    </Form>
  );
}

export default withRouter(ShowInstances);
