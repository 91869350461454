import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useBuildRPM } from "./useBuildRPM";
import Loading from "./Loading";
import { useFetchInstances } from "./useFetchInstances";
import ResultsTable from "./ResultsTable";
import InstancesAlert from "./InstancesAlert";
import ShowInstances from "./ShowInstances";
import { Container, Row, Col } from "react-bootstrap";

const CreateRPM = ({
  userdetails,
  datarecords,
  formData,
  setForm,
  navigation,
}) => {
  const {
    tlp_gi_name,
    truncate_table,
    regression_name,
    transaction_startdate,
    transaction_enddate,
    pl_startdate,
    pl_enddate,
    gi_startdate,
    gi_enddate,
  } = formData;

  const data_name = datarecords.DataID.substring(0, 20);

  console.log("User Details in CreateRPM", userdetails);
  console.log("User Email in CreateRPM", userdetails.email);

  //Docker Environment variables use to create the RPM:
  const myInit = {
    body: {
      DATAID: datarecords.DataID,
      REGION: datarecords.Region,
      PROD_URL: datarecords.ProdUrl,
      DATA_TYPE: datarecords.DataType,
      DATA_VERSION: datarecords.DataVersion,
      TRUNCATE_TABLE: truncate_table.toString(),
      TLP_GLOBAL_INPUT_NAME: tlp_gi_name,
      TLP_REGRESSION_NAME: regression_name,
      DATA_BUCKET: datarecords.dataBucket,
      S3_FILES: datarecords.s3Key,
      USER_EMAIL: userdetails.email,
      TRANSACTION_KEY: datarecords.s3Key,
      TRANSACTION_DATA_NAME: data_name,
      TRANSACTION_FILE_NAME: data_name + ".csv",
      TRANSACTION_START_DATE: transaction_startdate,
      TRANSACTION_END_DATE: transaction_enddate,
      GLOBALINPUT_START_DATE: gi_startdate,
      GLOBALINPUT_END_DATE: gi_enddate,
      PRICELIST_START_DATE: pl_startdate,
      PRICELIST_END_DATE: pl_enddate,
    },
  };

  const { data, loading } = useBuildRPM(myInit);

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    if (!data) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds, data]);

  const { running_instances, stopped_instances } = useFetchInstances(
    datarecords
  );

  return (
    <div>
      <br />
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Container>
              <Row>
                <Col>
                  <InstancesAlert
                    running_instances={running_instances}
                    stopped_instances={stopped_instances}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ResultsTable
                    data={data}
                    formData={formData}
                    tlp_gi_name={tlp_gi_name}
                    regression_name={regression_name}
                    time={seconds}
                  />
                </Col>
                <Col md="auto">
                  <ShowInstances
                    navigation={navigation}
                    formData={formData}
                    setForm={setForm}
                    running_instances={running_instances}
                    stopped_instances={stopped_instances}
                  />
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
      <br />
    </div>
  );
};

export default withRouter(CreateRPM);
