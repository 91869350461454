import { useEffect, useState } from "react";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useBuildRPM = (myInit) => {
  const transaction_id = myInit.body.DATAID;

  // console.log("TransactionID", transaction_id);

  const [rpmdata, getRPM] = useState(null);
  const [errorFetchedChecker, setErrorFetchedChecker] = useState(false);

  useEffect(() => {
    BuildRpm(myInit);
  }, []);

  async function BuildRpm(myInit) {
    try {
      console.log("My RPM inputs: ", myInit);
      const FargateData = await API.post("dataloadsapi", "/createrpm", myInit);
      console.log("Post Response:", FargateData);
      getRPM(FargateData.Items);
      console.log("RPM Data", rpmdata);
    } catch (err) {
      console.log("error:", err);
    }
  }

  const [stateRpm, setStateRPM] = useState({ data: null, loading: true });

  useEffect(() => {
    async function getData() {
      setStateRPM((stateRPM) => ({ data: stateRpm.data, loading: true }));
      try {
        const DataloadRecord = await API.get("dataloadsapi", "/dataload", {
          queryStringParameters: {
            DataID: transaction_id,
          },
        });
        console.log("Data Records:", DataloadRecord.Items);
        if (DataloadRecord.Items.DataStatus > 105) {
          setStateRPM({ data: DataloadRecord.Items, loading: false });
        } else {
          setStateRPM({ data: DataloadRecord.Items, loading: true });
          setErrorFetchedChecker((c) => !c);
        }
      } catch (err) {
        console.log("error fetchinga dataloads waiting for 5 secs...", err);
        setErrorFetchedChecker((c) => !c);
      }
    }
    const timer = setTimeout(() => {
      getData();
    }, 5000);
    return () => {
      clearTimeout(timer);
      console.log("unmount");
    };
  }, [errorFetchedChecker]);

  return stateRpm;
};
