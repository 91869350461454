import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useValidateStage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dataid = params.get("id") || "";

  const [ValidateResults, setValidateResults] = useState({
    items: null,
    loading: true,
  });

  const validate_parameters = {
    body: {
      DataID: dataid,
      DataStatus: "StageValidated",
    },
  };

  useEffect(() => {
    async function updateStatusData() {
      try {
        const UpdateRecords = await API.post(
          "dataloadsapi",
          "/updatestatus",
          validate_parameters
        );
        console.log("UpdatedRecords: ", UpdateRecords);
        setValidateResults({ items: UpdateRecords.Items, loading: false });
      } catch (err) {
        console.log("Error: ", err);
      }
    }
    updateStatusData();
  }, [validate_parameters]);

  return ValidateResults;
};
