import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

export const useCodeDeploy = (setRefresh, refresh) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const transaction_id = params.get("id") || "";

  const [codedeploy_records, updateDataload] = useState({
    datarecords: null,
    running_instances: null,
    stopped_instances: null,
  });

  async function getData(transaction_id) {
    console.log("transaction:", transaction_id);
    try {
      const DataloadRecord = await API.get("dataloadsapi", "/dataload", {
        queryStringParameters: {
          DataID: transaction_id,
        },
      });
      const Item = DataloadRecord.Items;
      const status = Item.DataStatus;
      console.log("Data Records:", Item);
      const InstanceDetails = await API.get("dataloadsapi", "/instances", {
        queryStringParameters: {
          product: "npo",
          accountId: Item.AwsAccountId,
          status: Item.DataStatus,
          region: Item.Region,
          vertical: Item.Vertical,
          BusinessType: Item.BusinessType,
        },
      });
      console.log("Instance Details: ", InstanceDetails);
      const InstanceStoppedDetails = await API.get(
        "dataloadsapi",
        "/instances",
        {
          queryStringParameters: {
            product: "npo",
            inst_state: "stopped",
            accountId: Item.AwsAccountId,
            status: Item.DataStatus,
            region: Item.Region,
            vertical: Item.Vertical,
            BusinessType: Item.BusinessType,
          },
        }
      );
      console.log("Instance Stopped Details: ", InstanceStoppedDetails);
      updateDataload({
        datarecords: DataloadRecord.Items,
        running_instances: InstanceDetails.Items,
        stopped_instances: InstanceStoppedDetails.Items,
      });
      setRefresh(false);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  useEffect(() => {
    console.log("Refresh: ", refresh);
    getData(transaction_id);
    return () => {
      console.log("unmount");
    };
  }, [transaction_id, refresh]);

  return codedeploy_records;
};
