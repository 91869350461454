import React, { useEffect, useState } from "react";
import { useForm } from "react-hooks-helper";
import CompletedTable from "./CompletedTable.js";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

Amplify.configure(awsconfig);

const defaultData = {
  choice_selected: "",
};

function Completed() {
  const [rpmdata, setRPMData] = useState([]);
  const [enablerefresh, setEnableRefresh] = useState(false);
  const [formData, setForm] = useForm(defaultData);

  useEffect(() => {
    if (enablerefresh) {
      const interval = setInterval(() => {
        getProgressData();
      }, 5000);
      return () => clearInterval(interval);
    } else {
      getProgressData();
      setEnableRefresh(true);
    }
  }, [enablerefresh]);

  async function getProgressData() {
    try {
      const UpdateRecords = await API.post("dataloadsapi", "/updatestatus");
      console.log("Update Records: ", UpdateRecords);
      const RecordsRPMData = await API.get("dataloadsapi", "/records", {
        queryStringParameters: {
          status: "700",
          DayLimit: "30",
        },
      });
      console.log("DataloadData:", RecordsRPMData);
      setRPMData(RecordsRPMData.Items);
    } catch (err) {
      console.log("error fetching dataloads...", err);
    }
  }

  const props = {
    setEnableRefresh,
    setForm,
    formData,
    rpmdata,
  };

  if (rpmdata.length > 0) {
    return <CompletedTable {...props} />;
  } else {
    return (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        &nbsp;&nbsp;Loading...
      </Button>
    );
  }
}

export default Completed;
