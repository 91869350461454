import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

function CompletedTable({ rpmdata }) {
  const renderComponent = (component, index) => {
    const props = {
      action: component.NextAction,
      label: component.NextActionLabel,
      dataid: component.DataID,
      url: component.ProdUrl,
    };

    if (component.DataStatus === "700") {
      return (
        <tr style={{ fontSize: 14 }} key={component.DataID}>
          <td>{index + 1}</td>
          <td>
            <Link to={`/viewdata?id=${component.DataID}`}>
              {component.DataVersion}
            </Link>
          </td>
          <td>{component.BankShortName.toUpperCase()}</td>
          <td>{component.DataType}</td>
          <td>{component.TargetInstance}</td>
          <td>{component.EventDateTime}</td>
          <td>{component.CompletedDate}</td>
          <td>{component.DurationTime}</td>
        </tr>
      );
    }
  };
  // Render the UI for your table
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr style={{ fontSize: 15 }}>
          <th>#</th>
          <th>Dataload Version</th>
          <th>Dataload Customer</th>
          <th>Dataload Type</th>
          <th>Target Instance</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Turnaround Time</th>
        </tr>
      </thead>
      <tbody>{rpmdata.map(renderComponent)}</tbody>
    </Table>
  );
}

export default CompletedTable;
