import React from "react";
import { useValidateStage } from "./useValidateStage";
import { Redirect } from "react-router";
import Loading from "./Loading";
import { Container, Row, Col } from "react-bootstrap";

const ValidateStage = () => {
  const { loading } = useValidateStage();

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <Redirect to="/progress" />;
  }

  // console.log("CodeDeploy form: ", formData);
  // console.log("Data Records: ", datarecords);
};

export default ValidateStage;
