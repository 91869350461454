import React from "react";
import logo from "../NomisLogomark.png";
import { Image, Col, Container, Row, Form, Button } from "react-bootstrap";

const ProductParams = ({ formData, setForm, navigation }) => {
  // Redirecting home if status is not New

  const { next } = navigation;
  const {
    bank_name,
    product_url,
    business_type,
    transaction_version,
  } = formData;

  console.log("URL product: ", product_url);

  return (
    <Container>
      <br />
      <Row className="justify-content-md-center">
        <Col>
          <Image src={logo} style={{ width: "20%" }} />
        </Col>
      </Row>
      <br />
      <Form>
        <Form.Group as={Row} controlId="bank_name">
          <Form.Label>Bank:</Form.Label>
          <Col sm={2}>
            <Form.Control name="bank_name" placeholder={bank_name} disabled />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="product_url">
          <Form.Label>URL:</Form.Label>
          <Col sm={4}>
            <Form.Control
              name="product_url"
              onChange={setForm}
              value={product_url}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>Business Type:</Form.Label>
          <Col sm={2}>
            <Form.Control
              name="business_type"
              onChange={setForm}
              as="select"
              defaultValue={business_type}
            >
              <option>consumer</option>
              <option>business</option>
              <option>renewals</option>
              <option>originations</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="transaction_version">
          <Form.Label>Transaction Version:</Form.Label>
          <Col sm={1}>
            <Form.Control
              name="transaction_version"
              onChange={setForm}
              as="select"
              defaultValue={transaction_version}
            >
              <option>v1</option>
              <option>v2</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Button size="lg" onClick={next} variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default ProductParams;
